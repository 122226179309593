
function Privacy() {

  return (
    <div>
    </div>
  );
}

export default Privacy;
